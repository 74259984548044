.auth-layout * {
  font-family: DINNextLTArabicRegular, "Cairo", Tahoma, Arial, Helvetica,
    sans-serif;
}

.auth-layout {
  min-height: 100vh;
  background-color: #fff;
  direction: rtl;
  text-align: start;
}
.auth-layout .auth-bg {
  background-image: url("../../images/portal/auth-bg.jpg");
  background-size: cover;
  background-position: top left;
  position: fixed;
  top: 80px;
  left: 0;
  min-height: calc(100vh - 80px);
  width: 57%;
}
.auth-layout .auth-body {
  display: flex;
}
.auth-layout .auth-content {
  margin-inline-end: auto;
  width: 43%;
  min-height: calc(100vh - 80px);
  position: relative;
  top: 80px;
  z-index: 2;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f8f7;
}
.auth-navbar {
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 3;
  box-shadow: -2px 0px 10px 5px rgba(0, 0, 0, 0.02);
}
.auth-navbar-wrapper {
  gap: 16px;
}
.auth-navbar .jeel-logo {
  display: flex;
  align-items: center;
  max-width: 65px;
}
.auth-navbar .jeel-logo img {
  object-fit: scale-down;
  max-width: 100%;
}
.auth-card {
  width: 100%;
  max-width: 440px;
  background-color: #fff;
  border: 1px solid #e0e2e1;
  border-radius: 14px;
  padding: 40px 32px;
  box-shadow: 0px 40px 100px -10px #0000001a;
}

.auth-card .auth-title {
  font-size: 24px;
  font-weight: 700;
  color: #2c2c2c;
}
.auth-card .auth-desc {
  font-size: 14px;
  font-weight: 400;
  color: #808191;
}

.custom-auth-input-wrapper {
  border: 1px solid #e7e7ea;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 45px;
  padding: 6px 12px;
}
.custom-auth-input-wrapper.with-icon {
}
.input-icon {
  margin-inline-end: 12px;
}
.custom-auth-input {
  width: 100%;
  height: 100%;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.cusror-pointer {
  cursor: pointer;
}
.custom-auth-input-wrapper .password-icon {
  margin-inline-start: 12px;
  color: #c5c7d7;
}
.custom-auth-input-wrapper input::placeholder {
  color: #c5c7d7;
}

.auth-card label {
  color: #484848;
}

.jeelPhoneNumberInput {
  border: 1px solid #e7e7ea;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 45px;
  padding: 6px 12px;
}
.jeelPhoneNumberInput input {
  width: 100%;
  height: 100%;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  text-align: end;
}

.jeelPhoneNumberInput input::placeholder {
  color: #c5c7d7;
}
.jeelPhoneNumberInput .PhoneInputCountryIcon {
  margin-inline-end: 6px;
}
.jeelPhoneNumberInput .PhoneInputCountry {
  margin-inline-end: 6px;
}
.auth-card .btn-auth {
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 6px;
  background-color: #88115f;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  font-weight: 500;
}
.auth-note {
  color: #74727c;
  font-size: 14px;
}
.auth-link {
  color: #88115f;
  background: none !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #88115f !important;
  padding-bottom: 2px;
  font-weight: 600;
}

.btn-auth-home {
  height: 50px;
  border: none;
  border-radius: 50px;
  background-color: #88115f !important;
  color: #fff !important;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  padding: 6px 24px;
}

@media (max-width: 991.98px) {
  .auth-layout .auth-bg {
    display: none;
  }
  .auth-layout .auth-content {
    width: 100%;
  }
}
