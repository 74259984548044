#editSeriesCard{
    border: none;
    height: 150px;
    margin-top: 10px;
}

.title_buttons_container{
    /* border: 5px solid green; */
    margin-top: 25px;
}

#episode-header{
    background-color:rgba(34,36,38,.10);
   
}

#editEpisodeCard{
    margin-left: 30px;
    padding-top: 0px;
    padding-left: 15px;
    padding-right: 15px;
}

#search-container{
    /* border: 5px solid green; */
    margin-left: 70%;
    margin-top: -25px;
    margin-bottom: -25px;
}

/* #search{
    /* border: 5px solid red; */
    /* float: right; */
    /* margin-left: 500px; */
 /* } */ 


/* Custom TabPane : */
ul.tabs-nav.nav.navbar-nav.navbar-left {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

li.tab {
    display: inline-block;
    padding: 10px;
    margin-right: 5px;
}

a.tab-link.custom-link {
    background: #6161a7;
    color: white;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: block;
    text-align: center;
    cursor: pointer;
}

a.tab-link.custom-link.active {
    background: #969696;
}



/* tabs */
.container {
  padding: 10px;
  /*background-color: lightgray;*/   
}

.tab {
  padding: 10px;
  background-color: white;
  /*border: 20px;*/
  border-radius: 3px 3px 0 0;
}

.selected {
  background-color: lightgray;
}

.selected2 {
  background-color: #ff03572e;
}

